
import { Vue, Component, Prop } from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue';
import { IIndustries } from '../../models';

@Component({
  components: {
    IconBreadcrumb,
  },
})
export default class BaseSelect extends Vue {
  $refs!: {
    options: HTMLElement;
    search: HTMLElement;
  };

  @Prop({ default: [] }) options!: any;
  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) value!: any;
  @Prop({ default: '' }) errorMessage!: string;
  @Prop({ default: '' }) description!: string;
  @Prop({ default: 'Select' }) defaultOptionText!: string;
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isSearchEnabled!: boolean;

  positionBlock = '';
  selectedOption = '';
  searchText = '';
  isShowOptions = false;
  isInputClicked = false;

  get parentOption(): string {
    return (
      this.options.find((option: IIndustries) => option.id === this.value || option.name === this.value)?.name
      || this.defaultOptionText
    );
  }

  get optionsPosition(): number {
    return this.isShowOptions ? this.$refs.options.getBoundingClientRect().bottom : 0;
  }
  get optionsSize(): any {
    return this.isShowOptions ? this.$refs.options.offsetHeight : 0;
  }

  showOptions(): void {
    if (!this.disabled) {
      this.isInputClicked = true;
      this.isShowOptions = !this.isShowOptions;
      this.$nextTick(() => {
        this.changePosition();
      });
    }
  }
  changePosition(): void {
    this.positionBlock = (window.innerHeight - this.optionsPosition) * 1.8 < this.optionsSize
      ? 'bottom: 78px; top: unset;'
      : 'top: 78px; bottom: unset;';
  }
  closeOptions(): void {
    this.isShowOptions = false;
    if (this.isInputClicked) this.$emit('checkField');
    this.isInputClicked = false;
  }
  selectOption(option: any): void {
    this.selectedOption = option.name;
    this.$emit('input', option.id || option.name);
    this.closeOptions();
    this.$nextTick(() => {
      this.changePosition();
    });
  }
  onInput(event: Event): void {
    const eventTarget = event.target as HTMLInputElement;
    this.$emit('inputSearch', eventTarget.value);
  }

  created(): void {
    Vue.use(vClickOutside);
  }
}
