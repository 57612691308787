import { instanceApi } from './instance';

// Get industries list
export const getIndustriesList = (): Promise<void> => instanceApi.get('api/business-value/industries');

// Get industries list after searching
export const getFilteredIndustriesList = async (typedText: string): Promise<any> => {
  const { data } = await instanceApi.get(`api/directory/industries?Parameter=${typedText}`);
  return data;
};

// Get locations list after searching
export const getFilteredLocationsList = async (typedText: string): Promise<any> => {
  const { data } = await instanceApi.get(`api/directory/locations?Parameter=${typedText}`);
  return data;
};
